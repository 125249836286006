import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";
import continis from "@continis/theme";
//import Sortable from "sortablejs";

Alpine.plugin(collapse);
Alpine.plugin(continis);

window.htmx = require('htmx.org');
//window.Sortable = Sortable;

Alpine.start();
